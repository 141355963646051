import styled, { css } from 'styled-components';
import { header1, header6, body2 } from '@westwing/ui-kit/typography';
import { forMidDesktop, forDesktop, forTablet, forWideScreens } from '@westwing/ui-kit/breakPoints';
import GenericLineSeparator from 'Client/components/Generic/LineSeparator';
import BellaHybridLink from 'Client/components/Generic/Links/BellaHybridLink';
import GenericProductSlider, { Wrapper } from 'Client/components/Generic/Sliders/ProductSlider';

export const SliderContainer = styled.section`
    color: ${({ theme }) => theme.colors.charcoal100};
    margin: 32px 0 0 40px;

    ${forMidDesktop`
        margin: 32px 20px 0;
    `}
`;

export const EmptyContainer = styled.section`
    visibility: hidden;
`;

export const LineSeparator = styled(GenericLineSeparator)`
    margin: 0 0 0 20px;
    width: calc(100% - 20px);

    ${forMidDesktop`
        margin: 0 auto;
        width: calc(100% - 30px);
    `}
`;

export const Headline = styled.h2`
    ${header6}
    line-height: 25px;
    margin-bottom: 5px;

    ${forMidDesktop`
        ${header1}
        line-height: 31px;
        margin-top: 0;
        margin-bottom: 0;
    `}
`;

export const CTALink = styled(BellaHybridLink)`
    ${body2}

    color: ${({ theme }) => theme.colors.charcoal100};
    text-decoration: underline;
`;

export const HorizontalScrollableContainer = styled.div`
    margin: 12px 0 40px -40px;

    ${forMidDesktop`
        margin: 12px -20px 40px;
    `}
`;

export const getProductSliderStyles = (sliderLength: number) => css`
    gap: 0 10px;
    padding-bottom: 0;

    grid-template-columns: 235px repeat(${sliderLength}, 175px);
    grid-template-rows: none;
    ${forTablet`
        grid-template-columns: 253px repeat(${sliderLength - 1}, 200px);
    `}

    ${forMidDesktop`
        grid-template-columns: 285px repeat(${sliderLength - 1}, 213px);
    `}

    ${forWideScreens`
        grid-template-columns: 383px repeat(${sliderLength - 1}, 303px);
    `}

    ${({ theme }) =>
        !theme.featureToggles.p100FreistellerImages &&
        css`
            grid-template-rows: 310px;

            ${forMidDesktop`
                grid-template-rows: 353px;
            `}

            ${forWideScreens`
                grid-template-rows: 473px;
            `}
        `}
`;

export const ProductSlider = styled(GenericProductSlider)<{ sliderLength: number }>`
    ${({ sliderLength }) => getProductSliderStyles(sliderLength)}
`;

export const LooksSlider = styled(Wrapper)<{ sliderLength: number }>`
    ${({ sliderLength }) => getProductSliderStyles(sliderLength)}
`;

export const ObserverPlaceholder = styled.div`
    content-visibility: auto;
    min-height: 362px;
    margin-bottom: 0;

    ${forTablet`
        min-height: 390px;
    `}

    ${forDesktop`
        min-height: 412px;
    `}

    ${forMidDesktop`
        margin: 0 -13px 0; // negative margin is needed bcs content-visibility hides overflowed component.
    `}
`;
