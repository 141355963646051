import * as React from 'react';
import styled from 'styled-components';
import { forMidDesktop, isDesktop } from '@westwing/ui-kit/breakPoints';
import LooksTileUiKit from '@westwing/ui-kit/LooksTile';
import { useGaTracking } from './useTracking';
import * as Styled from 'Client/components/ProductDetailPage/LooksInfusionSlider/LooksInfusionSlider.styles';
import { useGaTracking as useGaTrackingPdp } from 'Client/components/ProductDetailPage/LooksInfusionSlider/useTracking';
import useInView from 'Client/hooks/dom/useInView';
import useBellaTranslator from 'Client/hooks/useTranslator';
import { useBellaDispatch, useBellaSelector } from 'Client/redux/hooks';
import { setLDPKnownInfoAction } from 'Client/redux/lookDetail/actions';
import SliderTile, { SliderTileItem } from 'Client/components/Generic/ContentInfusionsSlider/SliderTile';
import ContentInfusionsSlider from 'Client/components/Generic/ContentInfusionsSlider';
import {
    contentfulHeightFormatter,
    ImageHeights,
} from 'Client/components/Generic/ContentInfusionsSlider/imageFormatter';
import LastSlideCard from 'Client/components/Generic/ContentInfusionsSlider/LastSlideCard';
import { looksSliderRatios, looksSliderLandscapeRatios } from 'Client/constants/aspectRatios';
import { LookSliderItem } from 'Client/redux/looksSlider/types';
import { WishlistBadge } from 'Client/components/Looks/WishlistBadge';
import { Direction } from '@westwing/ui-kit/Icons/StyledIcons';
import { ScrollDirection } from '../ScrollableElement';
import useSegmentFeatureTracking, { FeatureImpressionTracking } from 'Common/segment/useSegmentFeatureTracking';
import { SegmentFeatureCategory, SegmentFeatureInteractionType } from 'AppShell/appshell_types';

const Wrapper = styled.div`
    ${forMidDesktop`
        margin: 0 27px;
    `}
`;

type EnhancedSliderTileItemType = SliderTileItem & { slug: string; trackingName: string };

export interface LooksSliderPromotionProps {
    className?: string;
    hideSeparator?: boolean;
}

export const defaultLooksSliderSegmentData: FeatureImpressionTracking = {
    feature_category: SegmentFeatureCategory.Slider,
    feature_name: 'Shop the Look',
};

const LooksSliderPromotion: React.FC<LooksSliderPromotionProps> = ({ className, hideSeparator }) => {
    const dispatch = useBellaDispatch();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();

    const t = useBellaTranslator();
    const looks = useBellaSelector(state => state.looksSlider.looks) || [];
    const {
        homepageItems: { promotedLook },
    } = useBellaSelector(state => state.homepage);
    const filteredLooks = looks.filter(look => look.urlKey !== promotedLook?.slug);

    const { trackLookSliderWishlist } = useGaTrackingPdp();
    const { trackLookSliderImpression, trackLooksSliderClick, trackBannerImpression } = useGaTracking();

    const onLookClick = (look: LookSliderItem, trackingName: string) => {
        dispatch(setLDPKnownInfoAction(look));
        trackLooksSliderClick(trackingName);
        trackFeatureInteractions({
            ...defaultLooksSliderSegmentData,
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: `${look.title} ${filteredLooks.indexOf(look) + 1}/${filteredLooks.length}`,
        });
    };

    const containerRef = React.useRef<HTMLDivElement>(null);
    useInView({
        containerRef,
        options: { threshold: 0.5 },
        onVisibleCallback: () => {
            trackLookSliderImpression();
            trackFeatureImpression(defaultLooksSliderSegmentData);
        },
    });

    const trackArrowClicks = (direction: ScrollDirection) => {
        if (isDesktop()) {
            trackFeatureInteractions({
                ...defaultLooksSliderSegmentData,
                interaction_type: SegmentFeatureInteractionType.Click,
                interaction_label: direction === Direction.Left ? 'Left Arrow' : 'Right Arrow',
            });
        }
    };

    const ctaTrackingFn = () => {
        trackFeatureInteractions({
            ...defaultLooksSliderSegmentData,
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: 'See All',
        });
    };

    const onWishlistBadgeClick = (newIsSelectedValue: boolean) => {
        if (newIsSelectedValue) {
            trackLookSliderWishlist('Add Look to Wishlist');
        } else {
            trackLookSliderWishlist('Remove Look to Wishlist');
        }
    };

    const mapToCards = (look: LookSliderItem, i: number): EnhancedSliderTileItemType => {
        const trackingName = `${look.title} ${i + 1}/${looks.length}`;
        const desktopSrc = contentfulHeightFormatter(look.imgUrl, ImageHeights.LOOKS_DESKTOP);

        const details = {
            targetUrl: `/looks/${look.urlKey}/`,
            fallbackSrc: desktopSrc,
            desktopSrc,
            mobileSrc: contentfulHeightFormatter(look.imgUrl, ImageHeights.LOOKS_MOBILE),
            borderColor: look.color || '',
            title: look.title,
            alt: look.title || '',
            ctaText: t('Read and Shop'),
            onClick: () => onLookClick(look, trackingName),
            isLandscape: false,
            slug: look.urlKey,
            trackingName: look.trackingName,
            trackImpressionFunction: () => trackBannerImpression(trackingName),
        };

        const moodImgDesktopSrc = contentfulHeightFormatter(look.moodImgUrl, ImageHeights.LOOKS_DESKTOP);

        if (look.isLandscape && look.moodImgUrl) {
            details.fallbackSrc = moodImgDesktopSrc;
            details.desktopSrc = moodImgDesktopSrc;
            details.mobileSrc = contentfulHeightFormatter(look.moodImgUrl, ImageHeights.LOOKS_MOBILE);
            details.isLandscape = true;
        }
        return details;
    };
    const cards = filteredLooks.map(mapToCards);

    if (!cards.length) {
        return null;
    }

    return (
        <Wrapper className={className} ref={containerRef}>
            <Styled.LineSeparator hide={hideSeparator} />
            <ContentInfusionsSlider
                title={t('Homepage_Look_Slider_Headline')}
                data-testid="looks-slider"
                showBorder={false}
                noSideMarginsForWideScreen={false}
                ctaOptions={{ link: '/looks/', text: t('See all'), trackingFn: ctaTrackingFn }}
                onArrowClicks={trackArrowClicks}
            >
                {cards.map((look: EnhancedSliderTileItemType) => (
                    <SliderTile
                        ratio={look.isLandscape ? looksSliderLandscapeRatios : looksSliderRatios}
                        card={look}
                        key={look.title}
                        showBorder={false}
                    >
                        <LooksTileUiKit.TopRightBadge>
                            <WishlistBadge
                                lookName={look.title}
                                lookSlug={look.slug}
                                testId="look-slider-wishlist-badge"
                                onClick={onWishlistBadgeClick}
                            />
                        </LooksTileUiKit.TopRightBadge>
                    </SliderTile>
                ))}
                <LastSlideCard
                    title={t('Shop the Look')}
                    ctaText={t('See all')}
                    link="/looks/"
                    ratio={looksSliderRatios}
                    onClickAction={ctaTrackingFn}
                />
            </ContentInfusionsSlider>
        </Wrapper>
    );
};

export default LooksSliderPromotion;
