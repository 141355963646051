import useGa from 'Client/hooks/useGa';

const useGaTracking = () => {
    const gaTracking = useGa();

    const trackLooksSliderClick = (trackingName: string) => {
        gaTracking.homepage.trackLooksSliderTrackingClick(trackingName);
    };

    const trackLookSliderImpression = () => {
        gaTracking.homepage.trackSliderImpression('Shop the Look slider impression');
    };

    const trackBannerImpression = (trackingName: string) => {
        gaTracking.homepage.trackBannerImpression(trackingName);
    };

    return {
        trackLooksSliderClick,
        trackLookSliderImpression,
        trackBannerImpression,
    };
};

export { useGaTracking };
