/* eslint-disable camelcase */
import { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import useUnmount from '../hooks/useUnmount';
import { TrackPDPLooksInfusionActions } from 'Common/ga/types';
import useGa from 'Client/hooks/useGa';
import { useBellaSelector } from 'Client/redux/hooks';
import {
    ProductInterface,
    SegmentFeatureCategory,
    SegmentFeatureInteractionType,
} from '../../../../appshell/appshell_types';
import { useSegmentProductListViewedTracking } from '../../../../common/segment/useSegmentProductListViewedTracking';
import { SEGMENT_TRACKING_SOURCES } from '../../../../common/segment/types';
import useSegmentFeatureTracking, { FeatureImpressionTracking } from 'Common/segment/useSegmentFeatureTracking';
import { getSegmentProductPayload } from 'Client/components/ListingPage';

const useGaTracking = () => {
    const gaTracking = useGa();
    const { trackFeatureImpression, trackFeatureInteractions } = useSegmentFeatureTracking();
    const defaultSegmentParams: FeatureImpressionTracking = {
        feature_category: SegmentFeatureCategory.Slider,
        feature_name: 'Shop the Look',
    };

    const trackLookInfusionClicks = ({
        label,
        sku,
        trackingName = '',
    }: {
        label?: string;
        sku?: string;
        trackingName?: string;
    }) => {
        gaTracking.trackPDPInteractionEvent({
            action: TrackPDPLooksInfusionActions.LookInfusionClick,
            label: label || `PDP: ${sku}, Look CTA: ${trackingName}`,
        });
        trackFeatureInteractions({
            ...defaultSegmentParams,
            interaction_label: trackingName,
            interaction_type: SegmentFeatureInteractionType.Click,
        });
    };
    const trackLookSliderClicks = (sku: string, trackingName: string) => {
        gaTracking.trackPDPInteractionEvent({
            action: TrackPDPLooksInfusionActions.LookSliderClick,
            label: `PDP: ${sku}, Look CTA: ${trackingName}`,
        });
        trackFeatureInteractions({
            ...defaultSegmentParams,
            sku,
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: trackingName,
        });
    };

    const trackLookInfusionImpression = (label: string) => {
        gaTracking.trackPDPInteractionEvent({
            action: TrackPDPLooksInfusionActions.LookInfusionImpression,
            label,
        });
    };

    const trackLookSliderImpression = (sku: string) => {
        gaTracking.trackPDPInteractionEvent({
            action: TrackPDPLooksInfusionActions.LookSliderImpression,
            label: `PDP: ${sku}`,
        });
        trackFeatureImpression({
            ...defaultSegmentParams,
            sku,
        });
    };

    const trackLookSliderWishlist = (action: string) => {
        gaTracking.trackPDPInteractionEvent({
            action,
            label: 'PDP Look slider',
        });
    };

    const trackLooksSliderHomePageClick = (trackingName: string) => {
        gaTracking.homepage.trackLooksSliderTrackingClick(trackingName);
    };

    const trackLookSliderHomePageImpression = () => {
        gaTracking.homepage.trackSliderImpression('Shop the Look slider impression');
    };

    const trackLooksSliderArrowClick = (sku: string, label: string) => {
        trackFeatureInteractions({
            ...defaultSegmentParams,
            sku,
            interaction_type: SegmentFeatureInteractionType.Click,
            interaction_label: label,
        });
    };

    return {
        trackLookInfusionClicks,
        trackLookInfusionImpression,
        trackLookSliderClicks,
        trackLookSliderImpression,
        trackLookSliderWishlist,
        trackLooksSliderHomePageClick,
        trackLookSliderHomePageImpression,
        trackLooksSliderArrowClick,
    };
};

const useTrackCurrentProduct = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
    const [trackCurrentProduct, setTrackCurrentProduct] = useState(false);

    useUnmount(useCallback(() => setTrackCurrentProduct(false), []));

    return [trackCurrentProduct, setTrackCurrentProduct];
};

interface UseTrackingImpressionEventParams {
    isElementInView: boolean;
    products: ProductInterface[];
}

const useTrackingImpressionEvent = ({ isElementInView, products }: UseTrackingImpressionEventParams) => {
    const { trackLookInfusionImpression } = useGaTracking();
    const { sku } = useBellaSelector(state => state.productdetails.productdetail);
    const { trackingName } = useBellaSelector(state => state.productLooks.looks?.[0] || {});
    const [trackCurrentProduct, setTrackCurrentProduct] = useTrackCurrentProduct();
    const trackSegmentListingPageView = useSegmentProductListViewedTracking(
        SEGMENT_TRACKING_SOURCES.SHOP_THE_LOOK_SLIDER
    );

    useEffect(() => {
        if (isElementInView && trackCurrentProduct !== true) {
            trackLookInfusionImpression(`PDP: ${sku}, Look: ${trackingName}`);
            setTrackCurrentProduct(true);

            trackSegmentListingPageView({
                products: products.map(getSegmentProductPayload),
            });
        }
    }, [
        isElementInView,
        products,
        setTrackCurrentProduct,
        sku,
        trackCurrentProduct,
        trackLookInfusionImpression,
        trackSegmentListingPageView,
        trackingName,
    ]);
};

export { useGaTracking, useTrackingImpressionEvent };
