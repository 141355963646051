import * as React from 'react';
import styled, { css } from 'styled-components';
import {
    forWideScreens,
    forIE,
    forTablet,
    forDesktop,
    forMidDesktop,
    forMidWideScreens,
} from '@westwing/ui-kit/breakPoints';
import { StyledHorizontalGrid } from 'Client/components/ListingPage/ProductGrid/ProductGrid';
import { ProductGridProps, ProductsGrid } from 'Client/components/Generic/ProductsGrid';

export const Wrapper = styled(StyledHorizontalGrid)<{
    sliderLength: number;
}>`
    padding-left: 40px;
    &::after {
        display: block;
        content: '';
        height: 100%;
        width: 1px;
    }
    gap: 0 8px;
    grid-template-rows: ${({ theme }) => (theme.featureToggles.p100FreistellerImages ? 'auto' : '310px')};
    padding-bottom: 0;
    ${({ sliderLength }) => css`
        grid-template-columns: repeat(${sliderLength}, 175px);

        ${forTablet`
            grid-template-columns: repeat(${sliderLength}, 200px);
            gap: 0 12px;
        `}
        ${forDesktop`
            ${({ theme }) => (theme.featureToggles.p100FreistellerImages ? '' : 'margin-left: -4px;')}
            padding: 4px 0 0 4px;
        `}
        ${forMidDesktop`
            grid-template-columns: repeat(${sliderLength}, 213px);
            grid-template-rows: auto;
        `}
        ${forWideScreens`
            grid-template-columns: repeat(${sliderLength}, 303px);
            grid-template-rows: auto;
        `}
        ${forMidWideScreens`
            grid-template-columns: repeat(${sliderLength}, 291px);
        `}
    `}

    ${forIE`
        display: flex;
        flex-flow: row nowrap;
        overflow: scroll;
        height: 520px;
        :first-child {
            margin-left: 0;
        }
        height: 100%;
    `}
`;

type ProductSliderProps = ProductGridProps & {
    firstTile?: React.ReactElement | null;
    lastTile?: React.ReactElement | null;
    testId?: string;
};

const ProductSlider = ({
    className,
    products,
    list,
    ref,
    firstTile,
    lastTile,
    testId,
    children,
    promotions,
    hideNewProductBadge = false,
}: React.PropsWithChildren<ProductSliderProps>) => {
    let count = products.length + (promotions?.length || 0);
    if (firstTile) {
        count += 1;
    }
    if (lastTile) {
        count += 1;
    }
    if (children) {
        count += React.Children.count(children);
    }
    return (
        <Wrapper className={className} ref={ref} data-testid={testId} sliderLength={count}>
            {firstTile}
            <ProductsGrid
                promotions={promotions}
                products={products}
                list={list}
                isInline
                hideNewProductBadge={hideNewProductBadge}
            />
            {lastTile}
            {children}
        </Wrapper>
    );
};

export default ProductSlider;
